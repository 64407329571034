<template>
  <v-container id="locales" tag="section">
    <base-material-card
      color="primary"
      icon="mdi-translate"
      inline
      class="px-5 py-3"
      :title="$t('locales')"
    >
      <v-spacer></v-spacer>

      <template v-slot:after-heading></template>

      <!-- Boton nuevo registro -->
      <template v-slot:corner-button>
        <v-btn class="ma-2" elevation="2" color="primary" small @click.stop="dialog = true"
          >Nueva traducción</v-btn
        >
      </template>

      <!-- Overlay para retroalimentar al usuario -->
      <v-overlay :value="overlay">
        <v-progress-circular :size="50" color="success" indeterminate></v-progress-circular>
      </v-overlay>

      <!-- Barra de Búsqueda -->
      <v-text-field
        append-icon="mdi-magnify"
        class="ml-auto"
        hide-details
        label="Búsqueda"
        single-line
        style="max-width: 250px"
        v-model="search"
      />

      <!-- Dialogo cancelar -->
      <ConfirmationDialog v-model="isSure" @click="cancelLocalization" />

      <!-- Dialogo Agregar/Editar Traducción -->
      <v-dialog v-model="dialog" max-width="400px">
        <v-card>
          <!-- <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title> -->
          <v-card-text>
            <base-subheading :subheading="formTitle" />
            <v-container>
              <v-col cols="12">
                <v-text-field
                  dense
                  label="Ruta*"
                  maxlength="20"
                  required
                  v-model="$v.editedItem.etiqueta.$model"
                  :error-messages="etiquetaErrors"
                  @blur="$v.editedItem.etiqueta.$touch()"
                  @change="onTextChange"
                  @input="$v.editedItem.etiqueta.$reset()"
                ></v-text-field>
              </v-col>
            </v-container>
          </v-card-text>

          <!-- Botones y CheckList -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-checkbox label="Crear otra" v-model="checkbox" :disabled="isEditing"></v-checkbox>

            <v-btn color="error" text @click="checkChanges">Cancelar</v-btn>
            <v-btn color="primary" text @click="saveLocalization" :disabled="$v.$invalid"
              >Guardar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-divider class="mt-3" />

      <!-- Table con rutas y traducciones -->
      <v-data-table
        dense
        loading-text="Cargando... Espere por favor"
        :headers="headers"
        :items="locales"
        :loading="isLoading"
        :search.sync="search"
      >
        <!-- Slot para editar traduccion en español -->
        <template v-slot:[`item.locales[0].valor`]="props">
          <v-edit-dialog
            :return-value.sync="props.item.locales[0].valor"
            @cancel="cancel"
            @close="close"
            @open="open(props.item)"
            @save="save"
          >
            {{ props.item.locales[0].valor }}
            <template v-slot:input>
              <v-text-field
                v-model="editedItem.locales[0].valor"
                :rules="[max150chars]"
                label="Edit"
                single-line
                autofocus
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
        <!-- Termina Slot para editar traduccion en español -->
        <!-- Slot para editar traduccion en ingles -->
        <template v-slot:[`item.locales[1].valor`]="props">
          <v-edit-dialog
            :return-value.sync="props.item.locales[1].valor"
            @cancel="cancel"
            @close="close"
            @open="open(props.item)"
            @save="save"
          >
            {{ props.item.locales[1].valor }}
            <template v-slot:input>
              <v-text-field
                label="Edit"
                single-line
                v-model="editedItem.locales[1].valor"
                :rules="[max150chars]"
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
        <!-- Termina Slot para editar traduccion en ingles -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon class="mr-2" small @click="editLocalization(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteLocalization(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>

      <!-- Termina tabla editable -->
    </base-material-card>
  </v-container>
</template>

<script>
// import { getValidToken } from '@/store/helpers'
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

import ConfirmationDialog from '@/components/general/ConfirmationDialog.vue'

export default {
  name: 'Locales',
  components: {
    ConfirmationDialog,
  },
  data: () => ({
    changed: false,
    checkbox: false,
    dialog: false,
    editedIndex: -1,
    isEditing: false,
    isLoading: false,
    isSure: false,
    max150chars: (v) => v.length <= 150 || 'Traducción de masiado larga!',
    snack: false,
    snackColor: '',
    snackText: '',
    pagination: {},
    overlay: false,
    search: undefined,
    headers: [
      {
        text: 'Rutas',
        align: 'start',
        sortable: false,
        value: 'etiqueta',
      },
      { text: 'Español', value: 'locales[0].valor' },
      { text: 'Ingles', value: 'locales[1].valor' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
    locales: [],
    defaultItem: {
      etiqueta: '',
      locales: [
        { clave: '', valor: '' },
        { clave: '', valor: '' },
      ],
      activo: true,
    },
    editedItem: {
      etiqueta: '',
      locales: [
        { clave: '', valor: '' },
        { clave: '', valor: '' },
      ],
      activo: true,
    },
  }),
  mixins: [validationMixin],
  validations: {
    editedItem: {
      etiqueta: { required, minLength: minLength(3), maxLength: maxLength(20) },
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Nueva Ruta' : 'Edita Ruta'
    },
    etiquetaErrors() {
      const errors = []
      if (!this.$v.editedItem.etiqueta.$dirty) return errors
      !this.$v.editedItem.etiqueta.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.etiqueta.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.etiqueta.maxLength &&
        errors.push('Este campo debe de tener un máximo de 20 caracteres.')
      return errors
    },
  },
  async created() {
    this.obtenerLocales()
  },
  methods: {
    /**
     * @description confirma la cancelacion de la edicion en el formulario de
     * mantenimiento de localizaciones
     */
    cancel() {
      this.$store.commit('ALERT', {
        color: 'error',
        text: 'Cancelado',
      })
    },

    /**
     * @description confirma la cancelacion de la edicion en la tabla de
     * mantenimiento de tipos de vehiculos
     */
    cancelLocalization(isSure) {
      this.isSure = isSure
      if (this.isSure) {
        this.closeLocalization(false)
        this.changed = false
      }
    },

    /**
     * @description Revisa si hay cambios en el formulario de mantenimiento de
     * localizaciones
     */
    checkChanges() {
      if (this.changed) {
        this.isSure = true
      } else {
        this.closeLocalization(false)
        this.changed = false
      }
    },

    /**
     * @description Envia mensaje a consola
     */
    close() {
      console.info('Dialog closed')
    },

    /**
     * @description Cierra el diálogo de mantenimiento de localizaciones
     */
    closeLocalization(checkbox) {
      this.isSure = false
      this.checkbox = checkbox
      if (!this.checkbox) {
        this.dialog = false
        this.isEditing = false
      }
      this.$nextTick(() => {
        this.defaultItem.etiqueta = ''
        this.defaultItem.locales = [
          { clave: 'es', valor: '' },
          { clave: 'en', valor: '' },
        ]
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.$v.$reset()
      this.changed = false
    },

    /**
     * @description elimina un registro de la tabla y de la base de datos
     */
    async deleteLocalization(item) {
      if (confirm('Esta seguro que desea eliminar este registro?')) {
        const index = this.locales.indexOf(item)
        this.locales.splice(index, 1)
        console.log(item._id)
        let payload = { id: item._id }
        this.$store
          .dispatch('localization/deleteLocalization', payload)
          .then((response) => {
            this.$store.commit('ALERT', {
              color: 'success',
              text: response.data.msg,
            })
            this.overlay = false
          })
          .catch((err) => {
            this.$store.commit('ALERT', {
              color: 'error',
              text: err.response.data.msg,
            })
            this.overlay = false
          })
      }
    },

    /**
     * @description prepara un registro para su edición y muestra el dialogo
     * para editar localizaciones
     */
    editLocalization(item) {
      this.isEditing = true
      this.editedIndex = this.locales.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    /**
     * @description Obtiene un listado de todos los locales
     */
    obtenerLocales() {
      this.isLoading = true

      let payload = {}
      this.$store
        .dispatch('localization/fetchLocales', payload)
        .then((response) => {
          this.locales = response.data
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.error,
            // Enviar a inicio de sesión
          })
        })
      // }
    },

    /**
     * @description Marca que ha cambiado el valor de un campo
     */
    onTextChange() {
      this.changed = true
    },

    /**
     * @description Abre un item
     */
    open(item) {
      this.editedIndex = this.locales.indexOf(item)
      this.editedItem = Object.assign({}, item)
    },

    /**
     * @description Guarda el contenido del dialogo ya sea para una localización nueva o
     * la edicion de una localización
     */
    async saveLocalization() {
      this.overlay = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        let body = {
          etiqueta: this.editedItem.etiqueta,
          locales: [
            { clave: 'es', valor: '' },
            { clave: 'en', valor: '' },
          ],
        }

        let payload = { body: body }
        if (this.editedIndex > -1) {
          payload.body.locales[0].clave = 'es'
          payload.body.locales[0].valor = this.editedItem.locales[0].valor
          payload.body.locales[1].clave = 'en'
          payload.body.locales[1].valor = this.editedItem.locales[1].valor
          payload.id = this.editedItem._id
          this.$store
            .dispatch('localization/editLocalization', payload)
            .then(() => {
              this.overlay = false
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'La ruta se actualizo con éxito',
              })
            })
            .catch((err) => {
              // console.log(err.response.data.errores[0].msg)
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.errores[0].msg,
                // Enviar a inicio de sesión
              })
              this.overlay = false
            })
          Object.assign(this.locales[this.editedIndex], this.editedItem)
        } else {
          this.$store
            .dispatch('localization/addLocalization', payload)
            .then((response) => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'La ruta se creo con éxito',
              })
              this.locales.push(response.data)
              this.overlay = false
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.msg,
                // Enviar a inicio de sesión
              })
              this.overlay = false
            })
        }
        this.closeLocalization(this.checkbox)
      }
    },

    /**
     * @description Guarda el contenido de un idioma nuevo o
     * la edicion de un idioma
     */
    async save() {
      let body = {
        etiqueta: this.editedItem.etiqueta,
        locales: [
          { clave: 'es', valor: this.editedItem.locales[0].valor },
          { clave: 'en', valor: this.editedItem.locales[1].valor },
        ],
      }
      let payload = { body: body, id: this.editedItem._id }
      this.$store
        .dispatch('localization/editLocalization', payload)
        .then(() => {
          this.$store.commit('ALERT', {
            color: 'success',
            text: 'Traducción guardada',
          })
        })
        .catch((err) => {
          // console.log(err.response.data.errores[0].msg)
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.errores[0].msg,
            // Enviar a inicio de sesión
          })
          this.overlay = false
        })
    },
  },
}
</script>
